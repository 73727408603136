<template>
  <div>
    <el-row :gutter="10">
      <el-col :span="8" :offset="8">
        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="140px" class="mt-4">
          <el-form-item label="来源仓库" prop="fromLogicWarehouseCode">
            <el-select v-model="ruleForm.fromLogicWarehouseCode" :placeholder="$t('page.selectPlaceholder')" style="width:100%" filterable>
              <el-option
                v-for="item in warehouseList"
                :key="item.logicWarehouseCode"
                :value="item.logicWarehouseCode"
                :label="item.logicWarehouseName"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="目的仓库" prop="logicWarehouseCode">
            <el-select v-model="ruleForm.logicWarehouseCode" :placeholder="$t('page.selectPlaceholder')" style="width:100%" filterable>
              <el-option
                v-for="item in warehouseList"
                :key="item.logicWarehouseCode"
                :value="item.logicWarehouseCode"
                :label="item.logicWarehouseName"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="收货单类型" prop="dataSources">
            <el-select v-model="ruleForm.dataSources" filterable style="width:100%" :placeholder="$t('page.selectPlaceholder')">
              <el-option label="标准入库" value="1" />
              <el-option label="退货入库" value="2" />
            </el-select>
          </el-form-item>
          <el-form-item label="入库类型" prop="depositType">
            <el-select v-model="ruleForm.depositType" filterable style="width:100%" :placeholder="$t('page.selectPlaceholder')">
              <el-option label="采购入库" value="1" />
              <el-option label="调拨入库" value="2" />
            </el-select>
          </el-form-item>
          <el-form-item label="关联单号" prop="orderId">
            <el-input v-model="ruleForm.orderId" placeholder="请输入" />
          </el-form-item>
          <el-form-item prop="receiveTime" label="最晚收货时间">
            <el-date-picker
              v-model="ruleForm.receiveTime"
              type="datetime"
              value-format="yyyy-MM-dd hh:mm:ss"
              placeholder="选择日期时间"
            />
          </el-form-item>
          <el-form-item label="是否经过香港">
            <el-radio-group v-model="ruleForm.isThrough">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item ref="file" label="附件" prop="file">
            <el-upload
              ref="uploadForm"
              action=""
              :http-request="uploadFile"
              :limit="1"
              :on-remove="remove"
              :file-list="fileList"
              :auto-upload="false"
              :on-change="fileDateChange"
            >
              <el-button type="primary">导入文件</el-button>
              <el-button type="text" @click="downTemplate('VWMS_COLLESTTASK')">模板下载</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="4" :offset="10">
        <el-button type="primary" class="mt-4" :loading="checkLoading" @click="save">提交</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { findDownloadUrl } from '@/api/listSelection'
import { downloads } from '@/utils'
import { insertCollectTask } from '@/api/imsWidget.js'
import { getWarehouse } from '@/api/stockin'
export default {
  data() {
    return {
      checkLoading: false,
      ruleForm: {
        logicWarehouseCode: '',
        fromLogicWarehouseCode: '',
        file: '',
        depositType: '',
        dataSources: '',
        receiveTime: '',
        orderId: '',
        isThrough: 1
      },
      fileList: [],
      file: '',
      warehouseList: [],
      rules: {
        logicWarehouseCode: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        fromLogicWarehouseCode: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        depositType: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        file: { required: true, message: '必填', trigger: 'change' },
        dataSources: { required: true, message: '必填', trigger: 'change' },
        receiveTime: { required: true, message: '必填', trigger: 'change' },
        orderId: { required: true, message: '必填', trigger: 'blur' }

      }
    }
  },
  computed: {
    saveParams() {
      const formDataParams = new FormData()
      let params = {}

      const { file, logicWarehouseCode, fromLogicWarehouseCode, depositType, dataSources, receiveTime, orderId, isThrough } = this.ruleForm
      const { platformCode, shopCode, siteCode, warehouseCode, warehouseName, logicWarehouseName, sourceWarehouseName, cargoOwnerCode, channelCode } = this.warehouseList.find(item => item.logicWarehouseCode === this.ruleForm.logicWarehouseCode)

      params = Object.assign({}, { platformCode, shopCode: shopCode || '', siteCode: siteCode || '', warehouseCode, warehouseName: warehouseName || sourceWarehouseName, logicWarehouseName, cargoOwnerCode, cargoOwnerName: cargoOwnerCode, channelCode }, { logicWarehouseCode, fromLogicWarehouseCode, depositType, dataSources, receiveTime, orderId, isThrough })
      if (isThrough === 0) {
        delete params.isThrough
      }
      params = Object.assign({}, params, { file })
      Object.keys(params).forEach(key => formDataParams.set([key], params[key]))

      return formDataParams
    }
  },
  mounted() {
    this._queryWarehouseList()
  },
  methods: {
    async downTemplate(key) {
      const { datas } = await findDownloadUrl(key)
      downloads(datas)
    },
    async _queryWarehouseList() {
      const { datas } = await getWarehouse()
      this.warehouseList = datas
    },
    save() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          if (this.ruleForm.dataSource + '' === '1') {
            this.$refs.uploadForm.submit()
          } else {
            this.uploadFile()
          }
        }
      })
    },
    fileDateChange(file, fileList) {
      this.fileList = fileList
      this.ruleForm.file = file.raw
      this.$refs.file.clearValidate()
    },
    remove() {
      this.ruleForm.file = ''
      this.fileList = []
    },
    async uploadFile() {
      try {
        this.checkLoading = true
        const { code, msg } = await insertCollectTask(this.saveParams)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          Object.assign(this.ruleForm, this.$options.data.call(this).ruleForm)
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.checkLoading = false
        this.ruleForm.file = ''
        this.fileList = []
        this.$refs.ruleForm && this.$refs.ruleForm.clearValidate()
        this.ruleForm = this.$options.data().ruleForm
      }
    }
  }
}
</script>

<style>

</style>
