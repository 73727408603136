import request from '@/utils/request'
/**
 *
 * @description wms小工具
 * @export Promise
 * @returns Json
 */

// 强行 增加拣货数量
export function insertCollectTask(data) {
  return request({
    url: `inbound/insertCollectTask`,
    method: 'post',
    data
  })
}
